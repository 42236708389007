@import "grid";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


* {
  --font-family: 'Inter', sans-serif;
}


/* ==========================================================================================
   -- Variables --
========================================================================================== */

:root {
  --theme-color-blue: #0B95EB;
  --theme-color-navy: #141E35;
  --text-color-navy: #141E35;
  --text-white-color: #ffffff;
}


/* ==========================================================================================
   -- Reset --
========================================================================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
}

img {
  max-width: 100%;
}

:active,
:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 16px;
  font-weight: 600;
}

table p {
  margin-bottom: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

a:hover {
  text-decoration: unset;
  color: var(--theme-color2);
}

a:focus {
  outline: unset;
  box-shadow: unset !important;
}

.btn {
  color: var(--text-white-color);
  padding: 6px 25px;
  font-size: 14px;
  border-radius: 0px;
}

.stroked-btn {
  border: 1px solid var(--text-white-color);
  border-radius: 0px;
  transition: all .3s;
  margin-left: 10px;
}

.stroked-btn:hover {
  background-color: var(--theme-color-blue);
  color: white;
}

.desktop-view-btn {
  .login-btn {
    &:hover {
      background-color: var(--theme-color-blue);
      color: white;
    }
  }
}

.theme-blue-btn {
  background-color: var(--theme-color-blue);
  padding: 11px 60px;
  position: relative;
  overflow: hidden;
}

.theme-blue-btn::before {
  content: "";
  position: absolute;
  top: -30px;
  left: -85px;
  height: 100px;
  width: 70px;
  background: rgba(255, 255, 255, .3);
  transform: rotate(20deg);
}

.theme-blue-btn:hover {
  &::before {
    left: 108%;
    transition: all .5s;
  }

  color: var(--text-white-color);
  background-color: var(--theme-color-blue);
}

.color-black {
  color: var(--text-color-navy);
}

.theme-blue-btn:hover {
  box-shadow: 1px 5px 32px #141e3540;
}

.company-logo {
  height: 50px;
  width: 160px;
}

/* ==========================================================================================
   -- Preloader --
========================================================================================== */

.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--theme-color1);
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


/* ==========================================================================================
   -- Top Request Section --
========================================================================================== */

.top-request-section {
  background-color: var(--theme-color-blue);
  color: var(--text-white-color);

  padding: 15px 0px;

  span {
    font-size: 15px;
  }
}


/* ==========================================================================================
   -- Header --
========================================================================================== */

.header {
  background-color: var(--theme-color-navy);
  position: sticky;
  top: -1px;
  z-index: 999;
  padding: 10px 0px;

  .navbar-nav {
    .nav-item {
      margin: 0px 10px;

      .nav-link {
        color: var(--text-white-color);
        font-size: 14px;
        padding: 8px 0px;
        //transition: all 0.3s;
        //&:hover{
        //  border-bottom: 2px solid white;
        //}
      }
    }
  }
}

a.company-logo img {
  margin-right: 25px;
}

//.active {
//  border-bottom: 2px solid white;
//}


.ai {
  position: relative;
  margin: 20px 32px 25px 50px;
}

.ai {
  span {
    color: #fff;
    font-size: 66px;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.ai span:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #ffffff;
}

.ai span:nth-child(2) {
  color: #ffffff;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
                    0% 45%,
                    16% 44%,
                    33% 50%,
                    54% 60%,
                    70% 61%,
                    84% 59%,
                    100% 52%,
                    100% 100%,
                    0% 100%
    );
  }

  50% {
    clip-path: polygon(
                    0% 60%,
                    15% 65%,
                    34% 66%,
                    51% 62%,
                    67% 50%,
                    84% 45%,
                    100% 46%,
                    100% 100%,
                    0% 100%
    );
  }
}

/* ==========================================================================================
   -- Generative AI Section --
========================================================================================== */

.generative-ai {
  background-color: var(--theme-color-navy);
  text-align: center;
  padding: 120px 0px 90px;

  h1 {
    font-size: 75px;
    color: var(--text-white-color);
  }

  .enterprise-data-list {
    margin: 40px 0px 50px;

    span {
      color: var(--text-white-color);
      padding: 0px 15px;
    }
  }

  .ai-image-container {
    margin-top: 70px;
  }
}

.enterprise-data-list span:nth-child(2) {
  border-left: 2px solid #6A6A6A;
  border-right: 2px solid #6A6A6A;
}


/* ==========================================================================================
   -- Counter Section --
========================================================================================== */

.counter-section {
  background-color: var(--theme-color-blue);
  padding: 70px 0px;

  .counter-contant {
    text-align: center;

    h3 {
      color: white;
      font-weight: 700;
      font-size: 60px;
    }

    span {
      color: white;
      text-transform: uppercase;
    }
  }
}


/* ==========================================================================================
   -- Hallucination Section --
========================================================================================== */

.hallucination-section {
  padding: 70px 0px;

  h5 {
    font-size: 28px;
    margin-bottom: 25px;
    color: var(--theme-color-navy);
    font-weight: 700;
  }

  .hallucination-icon {
    display: flex;
    justify-content: center;

    .logo-content-penal-home {
      margin: 0 15px;
      width: 50px;
      height: 50px;
    }

    img {
      margin: 0px 15px;
    }
  }
}


/* ==========================================================================================
   -- Enterprise Data Section --
========================================================================================== */

.enterprise-data-section {
  padding: 70px 0px;
}

.collapse-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 8px;

  svg {
    transform: rotate(180deg);
  }
}

.collapsed {
  svg {
    transform: rotate(0deg);
  }
}

.collapse-add-btn {
  a {
    padding: 10px;
    border-radius: 6px;
    margin-right: 8px;
  }

  .dark-btn {
    background-color: var(--theme-color-navy);
  }
}

.Private-list-data {
  margin-top: 20px;

  span {
    margin-bottom: 6px;
    display: inherit;
    color: #6A6A6A;
    font-weight: 500;
    font-size: 15px;
  }

  ul {
    padding: 0px;

    li {
      list-style: none;
      font-size: 12px;
      color: #6A6A6A;
      margin-block: 2px;
    }
  }
}

.dropdown-toggle.show {
  svg {
    transform: rotate(180deg);
  }
}

.common-title {
  h5 {
    font-size: 28px;
    margin-bottom: 25px;
    color: var(--theme-color-navy);
    font-weight: 700;
  }

  p {
    color: #6A6A6A;
  }
}

.dataset-contant-body {
  //background-color: #F8FAFE;
  text-align: center;

  label {
    font-weight: 600;
    color: #141E35;
    font-size: 14px;
  }

  .dropdown-menu {
    li {
      a {
        font-size: 12px;
        white-space: unset;
      }
    }
  }

  .inner-container {
    background-color: white;
    padding: 15px;
    height: 100%;
  }

  svg {
    width: 100%;
  }
}

.total-sales-dropdown {
  position: relative;

  button {
    width: 100%;
    text-align: left;
    background-color: white;
    color: black;
    padding: 10px 10px;
    border: 1px solid var(--theme-color-blue);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    border: none;
    box-shadow: 0px 2px 5px gainsboro;
    margin-top: 4px;
    z-index: 1;

    li {
      a {
        font-size: 12px;
        white-space: unset;
      }
    }
  }
}


/* ==========================================================================================
   -- Introducing Data Section --
========================================================================================== */

.introducing-data-section {
  padding: 70px 0px;

  .data-close-container {
    border: 1px solid #EDEDED;
    padding: 7px 10px;
    border-radius: 5px;
    font-weight: 500;

    button {
      width: unset !important;
      font-size: 12px;
      padding: 7px 10px !important;
      border-radius: 10px;

      svg {
        margin-right: 7px;
      }
    }
  }

  .data-model-container {
    border-top: 10px solid #f8fafe;
    margin: 15px -15px 0px -15px;
    padding: 13px 15px 0px;

    .data-model {
      label {
        font-size: 13px;
        color: #6A6A6A;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .data {
        span {
          background: linear-gradient(89deg, #ECEFF3 0%, #F9F8F8 53.13%, #ECEFF3 99.22%);
          width: 100%;
          height: 6px;
          display: block;
          margin-bottom: 7px;
          border-radius: 2px;
        }
      }
    }
  }
}


/* ==========================================================================================
   -- Reliable Data Section --
========================================================================================== */

.reliable-data-section {
  padding: 30px 0px;
}


/* ==========================================================================================
   -- Introducing Data Section --
========================================================================================== */

.cost-effective-data-section {
  padding: 70px 0px;
}


/* ==========================================================================================
   -- Reliable Data Section --
========================================================================================== */

.operators-carousel-section {
  padding: 70px 0px;
  background-color: #F8FAFE;

  .carousel-indicators {
    display: flex;
    flex-direction: column;
    position: unset;
    margin-right: unset;
    margin-left: unset;

    .carousel-btn {
      margin-bottom: 30px;

      button {
        width: 100% !important;
        height: unset !important;
        background-color: unset;
        flex: auto;
        display: contents;
        font-size: 21px;
        font-weight: 400;
        color: #5B6272;
      }
    }

    .why-us-label {
      color: var(--theme-color-blue);
      margin-bottom: 25px;
      font-weight: 600;
    }
  }

  .carousel-inner {
    img {
      height: 400px;
      object-fit: contain;
    }
  }
}

.carousel-indicators .active {
  color: black !important;
  font-weight: 700 !important;
}

.carousel-btn:last-child {
  margin-bottom: 0px !important;
}


/* ==========================================================================================
   -- Get Started Section --
========================================================================================== */

.get-started-section {
  padding: 70px 0px;

  .icon-container {
    img {
      margin: 10px 10px;
      height: 35px !important;
    }
  }

  .train-container {
    padding: 0px 40px 0px;
    text-align: center;
  }

  .connect-title {
    margin-top: 50px;

    span {
      background-color: var(--theme-color-blue);
      width: 30px;
      height: 30px;
      display: inline-block;
      font-size: 17px;
      align-items: center;
      line-height: 30px;
      border-radius: 100px;
      margin-right: 10px;
      color: white;
      text-align: center;
    }
  }

  .arrow-container {
    position: relative;

    &::before {
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      bottom: -41px;
      right: 0px;
      border-top: black solid 3px;
      border-left: black solid 3px;
      transform: rotate(135deg);
    }

    &::after {
      content: "";
      width: 100%;
      height: 3px;
      background-color: #000000;
      right: 0;
      bottom: -33px;
      position: absolute;
    }
  }
}


/* ==========================================================================================
   -- Use Cases Section --
========================================================================================== */

.use-cases-section {
  padding: 70px 0px;

  .cases-title {
    height: 100%;
    border-image-source: linear-gradient(to bottom, #0B95EB, #b2ff5900);
    border-right: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
  }

  .common-title {
    margin-bottom: 70px;
    padding-left: 40px;

    h5 {
      margin-bottom: 10px;
      font-size: 25px;
    }
  }

  .cases-title {
    h3 {
      font-size: 35px !important;
      font-weight: 700;
    }

    a {
      margin-top: 30px;
    }
  }
}


/* ==========================================================================================
   -- Footer Section --
========================================================================================== */

.footer-section {
  background-color: var(--text-color-navy);
  padding: 70px 0px 40px;
  color: white;

  p {
    font-size: 14px;
    color: white;
  }

  ul {
    padding: 0px;

    li {
      list-style: none;
      margin-bottom: 10px;

      a {
        color: white;
        font-size: 14px;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }

  .footer-right-contant {
    margin-top: 60px;
  }

  span {
    color: #CDD1E4;
    font-size: 12px;
  }

  .terms-link {
    color: #CDD1E4;
    font-size: 14px;

    &:hover {
      text-decoration: underline !important;
    }
  }
}


/* ==========================================================================================
   -- Form Section --
========================================================================================== */

.form-section {
  padding: 70px 0px;
  background: linear-gradient(180deg, #F8FAFE 0%, rgba(242, 251, 255, 0.00) 100%);

  .register-input {
    margin-bottom: 25px;

    label {
      font-size: 14px;
      color: var(--theme-color-navy);
      margin-bottom: 6px;
      font-weight: 600;
    }

    input {
      width: 100%;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      resize: vertical;
    }
  }

  .form-container {
    box-shadow: 4px 4px 16px 0px rgba(20, 30, 53, 0.12);
    padding: 30px;
    background-color: white;
    border-radius: 8px;
  }

  span {
    font-size: 15px;
    color: var(--theme-color-navy);
    margin-bottom: 6px;
    font-weight: 600;
  }

  .form-check {
    .form-check-input {
      border: 2px solid #6A6A6A;
    }

    label {
      color: #6A6A6A;
      font-size: 14px;
    }
  }

  .form-secure-data {
    display: flex;
    margin-bottom: 30px;

    svg {
      margin-right: 14px;
      margin-top: 3px;
    }

    h3 {
      font-size: 19px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }
}

.toggle span:before,
.toggle span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  transition: all .3s ease-out;
}

button.toggle {
  display: none;
}

.toggle span:before {
  top: -8px;
}

.toggle span:after {
  top: 8px;
}

.toggle span {
  width: 100%;
  height: 3px;
  background: white;
  display: block;
  position: relative;
}

.mobile-view-btn {
  display: none;
}

.top-arrow,
.botton-arrow {
  display: none;
}

.mobile-btn {
  display: none;
}


/* ==========================================================================================
   -- Making Data Section --
========================================================================================== */

.making-data-section {
  background-color: white;
  text-align: center;
  padding: 120px 0px 90px;

  h1 {
    font-size: 75px;
    color: var(--text-color-navy);
  }

  .about-icon {
    padding-top: 120px;
  }
}


/* ==========================================================================================
   -- Making Data Section --
========================================================================================== */

.leadership-section {
  background-color: #F2FBFF;
  padding: 90px 0px;

  h3 {
    margin-bottom: 40px;
  }

  .leadership-user-body {
    text-align: center;
    padding: 15px;
    transition: all 0.3s;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .profile-images {
      position: relative;

      .profile-image-frame {
        width: 115px;
        height: 115px;
        object-fit: cover;
        border-radius: 100%;
      }

      .social-link {
        position: absolute;
        background-color: #141e35;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 18px;
        padding: 8px;
        border-radius: 100%;
        bottom: 7px;
        box-shadow: 0px 0px 13px black;
      }
    }

    h4 {
      margin-top: 20px;
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 9px;
    }

    small {
      color: #a39494;
      display: block;
      margin-bottom: 7px;
    }

    span {
      color: var(--theme-color-blue);
      font-weight: 600;
    }

    &:hover {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      background-color: #141e35;

      h4 {
        color: white;
      }
    }
  }
}


/* ==========================================================================================
   -- Making Data Section --
========================================================================================== */

.industry-experts-section {
  background-color: white;

  .leadership-user-body {
    img {
      width: 160px;
      height: 160px;
    }

    .profile-images .social-link {
      position: absolute;
      right: 0px !important;
      bottom: 0px !important;

    }
  }
}


/* ==========================================================================================
   -- Supercharge Section  --
========================================================================================== */

.supercharge-section {
  padding: 90px 0px;
  background: var(--linear, linear-gradient(180deg, #F8FAFE 0%, rgba(242, 251, 255, 0.00) 100%));

  .supercharge-data {
    .title-container {
      padding: 0px 136px 35px;
      text-align: center;
    }

    span {
      width: 100%;
      display: block;
      background-color: white;
      box-shadow: 1px 1px 13px #dcdcdc69;
    }
  }
}


/* ==========================================================================================
   -- See Action Section  --
========================================================================================== */

.see-action-section {
  background-color: var(--theme-color-blue);
  color: white;
  text-align: center;

  .see-action-contant {
    padding: 60px 0px;

    h4 {
      font-size: 30px;
      margin-bottom: 20px !important;
    }

    a {
      background-color: var(--text-white-color) !important;
      color: var(--text-color-navy);
      font-weight: 700;
    }
  }
}


/* ==========================================================================================
   -- Smartlets Section  --
========================================================================================== */

.smartlets-section {
  background-color: #F2FBFF;
  padding: 50px 0px;
}


/* ==========================================================================================
   -- Rethinking Data Section  --
========================================================================================== */

.rethinking-data-section {
  padding: 90px 0px;

  p {
    color: #6A6A6A;
  }

  .rethinking-data {
    .rethinking-user {
      display: flex;
      color: #6A6A6A;

      .user-image {
        width: 50px;
        height: 50px;
        background-color: #EDEDED;
        display: inline-block;
        border-radius: 100px;
        margin-right: 15px;
      }

      h6 {
        color: #0B95EB;
        margin-bottom: 7px;
      }
    }
  }

  .read-more-btn {
    color: black;
    padding: 8px 20px;
    border: 1px solid #141E35;
    display: inline-block;
    font-weight: 500;
    position: relative;
    transition: all 0.3s;

    &:hover {
      background-color: var(--theme-color-blue);
      color: white;
    }
  }

  .rethinking-title {
    padding: 40px 0px 100px;

    h4 {
      font-size: 35px;
    }
  }

  .small-rethinking-card {
    .small-rethinking-data {
      padding: 30px;

      .rethinking-title {
        padding: 28px 0px 35px;

        h4 {
          font-size: 30px;
        }
      }
    }
  }
}


/* ==========================================================================================
   -- Rethinking Data Section  --
========================================================================================== */

.stay-date-section {
  padding: 60px 0px;
  background-color: var(--theme-color-blue);

  p {
    color: var(--text-white-color);
  }

  h5 {
    color: var(--text-white-color);
  }

  .subscribe-input {
    margin-top: 20px;
    display: flex;

    input {
      height: 42px;
      padding: 10px 15px;
      border: none;
      flex: 1;
    }

    button {
      background-color: #141E35;
      padding: 10px 20px;
      height: 42px;
      border: none;
      color: white;
      font-size: 13px;
    }
  }
}


/* ==========================================================================================
   -- Research List Data  --
========================================================================================== */

.research-list-data {
  .list-data {
    padding: 25px;
    box-shadow: 1px 1px 5px gainsboro;
    margin: 15px 0px;
    border-radius: 5px;
    background-color: white;

    .panel-title {

      font-size: 16px;
      font-weight: 600;

    }

    label {
      display: block;
      font-size: 14px;
      margin: 14px 0px;
      color: #50555c;
      line-height: 1.8;
    }

    .list-year {
      background-color: rgba(139, 139, 139, 0.1019607843);
      margin-right: 15px;
      font-size: 13px;
      padding: 5px 10px;
      border-radius: 5px;
      color: #0d6efd;
      position: relative;
      padding-left: 28px !important;

      &:before {
        content: "";
        position: absolute;
        top: 6px;
        height: 14px;
        width: 14px;
        background-size: contain;
        left: 8px;
        background-repeat: no-repeat;
        background-image: url("../../assets/image/book.png");
      }
    }
  }

  .list-search {
    display: flex;
    margin-top: 15px;

    input {
      flex: 1;
      margin-right: 15px;
      padding: 10px 13px;
      border: 1px solid gainsboro;
      border-radius: 5px;
    }

    select {
      padding: 10px;
      border: 1px solid gainsboro;
      border-radius: 5px;
    }
  }
}

/* ==========================================================================================
   -- Media Section --
========================================================================================== */

@media screen and (max-width: 1024px) {
  .rethinking-data-section {
    padding: 70px 0px 50px;

    .rethinking-title {
      padding: 20px 0px 35px;
    }
  }
}

@media screen and (max-width: 991px) {
  button.toggle {
    background: transparent;
    border: 0;
    width: 25px;
    height: 30px;
    cursor: pointer;
    outline: 0;
    padding: 0;
    display: block !important;
  }
  .desktop-view-btn {
    display: none;
  }
  .mobile-view-btn {
    display: block !important;
    margin-top: 10px;
    text-align: -webkit-right;
    padding-bottom: 10px;
  }
  .get-started-section .arrow-container::after {
    display: none;
  }
  .get-started-section .arrow-container::before {
    display: none;
  }
  .mobile-arrow {
    padding: 120px 0px;
  }
  .mobile-arrow {
    padding: 120px 0px;
    position: relative;
  }
  .top-arrow {
    display: block;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-51%, 25px);
  }
  .botton-arrow {
    display: block;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 991px) {
  .generative-ai h1 {
    font-size: 50px;
  }
  ul.navbar-nav {
    margin-top: 20px;
  }
  .rethinking-data-section .rethinking-title h4 {
    font-size: 23px;
  }
  .rethinking-data-section .small-rethinking-card .small-rethinking-data .rethinking-title h4 {
    font-size: 25px;
  }
  .making-data-section h1 {
    font-size: 40px;
  }
  .making-data-section {
    padding: 70px 0px 70px;
  }
  .supercharge-section {
    padding: 40px 0px;
  }
  .enterprise-data-section,
  .introducing-data-section {
    padding: 25px 0px 0px;

    .common-title {
      margin-bottom: 25px;
    }
  }
  .cost-effective-data-section {
    padding: 20px 0px;

    .common-title {
      margin-bottom: 20px;
    }
  }
  .ai {
    margin: 19px 29px 18px 40px;

    span {
      font-size: 50px;
    }
  }
}

@media screen and (max-width: 767px) {
  .use-cases-section {
    padding: 25px 0px;
    background: #F8FAFE;

    .common-title {
      margin-bottom: 35px;
      padding-left: 0px;

      h5 {
        margin-bottom: 10px;
        font-size: 22px;
      }
    }

    .cases-title {
      border-right: unset;

      h3 {
        font-size: 33px !important;
        margin-bottom: 30px;
      }
    }
  }
  .mobile-btn {
    display: block;
  }
  .leptop-btn {
    display: none;
  }
  .get-started-section {
    a {
      width: 100%;
    }
  }
  .cost-effective-data-section,
  .reliable-data-section,
  .introducing-data-section {
    .common-title {
      margin-bottom: 20px;
    }
  }
  .footer-section .footer-right-contant {
    margin-top: 30px;
  }
  .footer-section .terms-link {
    margin-top: 15px;
    display: block;
  }
  .footer-section {
    padding: 40px 0px 15px;
  }
  .form-section {
    padding: 40px 0px;
  }
  .stay-date-section {
    padding: 40px 0px;

    .common-title {
      margin-top: 30px;
    }
  }
  .rethinking-data-section {
    padding: 40px 0px 20px;
  }
  .right-rethinking-data {
    padding-top: 30px;
  }
  .leadership-section {
    padding: 40px 0px;
  }
  .making-data-section {
    padding: 50px 0px 50px;
  }
  .making-data-section .about-icon {
    padding-top: 50px;
  }
  .supercharge-section .supercharge-data .title-container {
    padding: 0px 0px 35px;
    text-align: center;
  }
  .supercharge-section .supercharge-data span {
    height: 250px;
  }
}

@media screen and (max-width: 576px) {
  .generative-ai {
    padding: 60px 0px 50px;
  }
  .counter-section {
    padding: 30px 0px 10px;

    .counter-contant {
      margin-bottom: 20px !important;

      h3 {
        font-size: 45px;
        margin-bottom: 5px;
      }
    }
  }
  .hallucination-section {
    padding: 40px 0px;

    .hallucination-icon {
      img {
        margin: 15px 25px;
      }
    }
  }
  .introducing-data-section {
    padding: 30px 0px;
  }
  .cost-effective-data-section {
    padding: 0px 0px 30px;
  }
  .common-title {
    h5 {
      font-size: 24px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
    }
  }
  .operators-carousel-section .carousel-inner img {
    height: 130px;
  }
  .operators-carousel-section .carousel-indicators .carousel-btn button {
    font-size: 18px;
  }
  .operators-carousel-section {
    padding: 25px 0px 30px;
  }
  .carousel-item {
    margin-top: 20px;
  }
  .generative-ai h1 {
    font-size: 35px;
  }
  .generative-ai .enterprise-data-list {
    margin: 25px 0px 35px;
    display: flex;
    flex-direction: column;
  }
  .enterprise-data-list span:nth-child(2) {
    border-left: none;
    border-right: none;
    margin: 8px 0px;
  }
  .top-request-section span {
    font-size: 11px;
  }
  .making-data-section h1 {
    font-size: 30px;
  }
  .making-data-section .about-icon {
    padding-top: 35px;
  }
  .carousel-two svg {
    height: 320px;
  }
  .ai {
    margin: 15px 20px 14px 29px;

    span {
      font-size: 35px;
    }
  }
}

@media screen and (max-width: 990px) {
  .leadership-section .leadership-user-body .profile-images .profile-image-frame {
    width: 115px !important;
    height: 115px !important;
  }

  .leadership-section .leadership-user-body h4 {
    font-size: 16px !important;
  }

  .leadership-section .leadership-user-body small {
    font-size: 12px !important;
  }
  .leadership-section .leadership-user-body span {
    font-size: 14px !important;
  }
}

#playButton {
  cursor: pointer;

  g:hover path {
    fill: #c7e5fa;
  }
}

.close-videos-model {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  padding: 15px;
  opacity: 1 !important;
  z-index: 100;
  border-radius: 100%;
}

.search-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 0px;

  img {
    width: 120px;
  }

  span {
    font-weight: normal;
    margin-top: 20px;
    color: #141e35;
  }
}

.terms-conditions-main-container * {
  font-size: 16px;
  line-height: 1.9;
}

.calendly-inline-widget{
  min-height: 700px;
}
